<template>
  <div>
    <div class="about">
      <div class="container is-max-desktop">
        <br><br>
        <h1 class="title is-1">{{kontakt[lang_number]}}</h1>
        <br>
        <div class="columns">
          <div class="column">
            <b>{{adresa[lang_number]}}</b>
            <address>{{load.shop_location.street}}<br>
              {{load.shop_location.zip}} {{load.shop_location.city}}<br>
              {{load.shop_location.country}}</address>
          </div>
          <div class="column">
            <b>{{faudaje[lang_number]}}</b>
            <p>{{load.company}}</p>
            <p>IČO: {{load.license.ICO}}</p>
          </div>
        </div>
        <div class="columns is-marginless is-paddingless is-gapless">
          <div class="column is-narrow">
            <b-icon icon="phone" type="is-primary"></b-icon>
          </div>
          <div class="column is-narrow">&nbsp;&nbsp;{{load.contact.phone}}</div>
        </div>
        <div class="columns is-marginless is-paddingless is-gapless">
          <div class="column is-narrow">
            <b-icon icon="mail" type="is-primary"></b-icon>
          </div>
          <div class="column is-narrow">&nbsp;&nbsp;{{load.contact.email}}</div>
        </div>
        <div class="columns is-hidden is-marginless is-paddingless is-gapless">
          <div class="column is-narrow">
            <b-icon icon="instagram" type="is-primary"></b-icon>
          </div>
          <div class="column is-narrow">&nbsp;&nbsp;@DomaNaPeci</div>
        </div>
      </div>
    </div>
    <iframe width="100%" height="200"
      src="https://maphub.net/embed_h/0i11rHPU6gmFh8W2?autoplay=1&directions=1&geolocation=1" frameborder="0"
      allow="geolocation"></iframe>
  </div>
</template>
<style scoped>
.about {
  position:fixed;
  background: url("../assets/whiteback.png");
  background-size: cover;
  background-position: top center;
  z-index: 5;
  margin-bottom: 15px;
  width:100%;
  height: calc(100% - 60px - 200px);

  padding-top: 113px;
}
iframe {
  width:100%;
  height: 200px;
  position:fixed;
  top: calc(100% - 60px - 200px)
}
</style>
<script>
import axios from 'axios';
  export default {
  created() {
   this.$parent.dark = false;
   this.loadInfo();
  },
  data() {
    return {
      load: {
        shop_location: {},
        contact: {},
        licence: {}
      },
      kontakt: [
        "Kontakt",
        "Contact",
        "연락",
        "Kontakt",
        "Kxontakt",
        "Kontakt"
      ],
      adresa: [
        "Adresa",
        "Address",
        "주소",
        "Anschrift",
        "Deikon",
        "Adres"
      ],
      faudaje: [
        "Fakturační údaje",
        "Billing information",
        "청구 정보",
        "Abrechnungsdaten",
        "Pxakturayī inpxon",
        "Informacje rozliczeniowe"
      ],
    }
  },
  methods: {
    loadInfo: function () {
      console.log(this.today);
      var data = new FormData();
      data.append('bin', '/apps/persephone/cgi/psinfo');
      data.append('stdin', 'get_info(1)');
      const hostname = "https://domanapeci.cz/fujin3/api/rest/rest_call.php";
      axios.post(hostname, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log(response);
          if (response.data.data.code == 0) {
            this.$buefy.snackbar.open({
              duration: 5000,
              message: response.data.data,
              type: 'is-danger',
              position: 'is-bottom-left',
              actionText: 'Undo',
              queue: false,

            })
          } else {
            this.load = response.data.data;

          }

        }).catch(function (error) {
          console.log(error);

        });

    },
  },
  computed: {
    lang_number() {
      return this.$parent.lang_number;
    },
    lang() {
      return this.$parent.lang;
    }
  }
  }
</script>